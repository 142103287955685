// src/App.js

import React, { useContext, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import AddMember from './components/AddMember';
import SearchMembers from './components/SearchMembers';
import AttendanceAnalysis from './components/AttendanceAnalysis';
import MultiLevelAnalysis from './components/MultiLevelAnalysis';
import UpdateMember from './components/UpdateMember';
import QrCheckIn from './components/QrCheckIn';
import MemberList from './components/MemberList';
import Login from './components/Login';
import ProtectedRoute from './components/ProtectedRoute';
import { AuthProvider, AuthContext } from './context/AuthContext';
import DigitalClockCard from './components/cards/DigitalClockCard';
import FooterCard from './components/cards/FooterCard';
import './styles/styles.css';

const App = () => {
    const { user, logout } = useContext(AuthContext);
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const handleLogout = async () => {
        await logout();
        setIsMenuOpen(false); // Collapse menu after logout
    };

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    const closeMenu = () => {
        setIsMenuOpen(false);
    };

    return (
        <AuthProvider>
            <Router>
                <div className="App">
                    <header className="App-header">
                        <h1>Welcome to Real Time Analytics</h1>
                        <div className="App">
                            <DigitalClockCard />
                        </div>
                        <nav>
                            <button
                                className="menu-toggle"
                                onClick={toggleMenu}
                                aria-expanded={isMenuOpen}
                                aria-controls="navigation-menu"
                                
                            >
                                ☰ Menu ☰
                            </button>
                            <ul
                                id="navigation-menu"
                                className={`App-header-navigation ${isMenuOpen ? 'open' : 'closed'}`}
                            >
                                {user ? (
                                    <>
                                        <li>
                                            <Link to="/" onClick={closeMenu}>Search Members</Link>
                                        </li>
                                        <li>
                                            <Link to="/addmember" onClick={closeMenu}>Add Member to Database</Link>
                                        </li>
                                        <li>
                                            <Link to="/analysis" onClick={closeMenu}>Attendance Analysis</Link>
                                        </li>
                                        <li>
                                            <Link to="/multilevelanalysis" onClick={closeMenu}>MultiLevel Attendance Analysis</Link>
                                        </li>
                                        <li>
                                            <Link to="/members" onClick={closeMenu}>Member List</Link>
                                        </li>
                                        <li>
                                            <Link to="/update" onClick={closeMenu}>Update Details</Link>
                                        </li>
                                        <li>
                                            <Link to="/qrcheckin" onClick={closeMenu}>QR Code Check-In</Link>
                                        </li>
                                        <li>
                                            <button onClick={handleLogout}>Logout</button>
                                        </li>
                                    </>
                                ) : (
                                    <>
                                        <li>
                                            <Link to="/login" onClick={closeMenu}>Login</Link>
                                        </li>
                                        <li>
                                            <Link to="/addmember" onClick={closeMenu}>Register today</Link>
                                        </li>
                                    </>
                                )}
                            </ul>
                        </nav>
                    </header>
                    <main>
                        <Routes>
                            <Route path="/login" element={<Login />} />
                            <Route path="/addmember" element={<AddMember />} />
                            <Route
                                path="/"
                                element={
                                    <ProtectedRoute>
                                        <SearchMembers />
                                    </ProtectedRoute>
                                }
                            />
                            <Route
                                path="/analysis"
                                element={
                                    <ProtectedRoute>
                                        <AttendanceAnalysis />
                                    </ProtectedRoute>
                                }
                            />
                            <Route
                                path="/multilevelanalysis"
                                element={
                                    <ProtectedRoute>
                                        <MultiLevelAnalysis />
                                    </ProtectedRoute>
                                }
                            />
                            <Route
                                path="/members"
                                element={
                                    <ProtectedRoute>
                                        <MemberList />
                                    </ProtectedRoute>
                                }
                            />
                            <Route
                                path="/update"
                                element={
                                    <ProtectedRoute>
                                        <UpdateMember />
                                    </ProtectedRoute>
                                }
                            />
                            <Route
                                path="/qrcheckin"
                                element={
                                    <ProtectedRoute>
                                        <QrCheckIn />
                                    </ProtectedRoute>
                                }
                            />
                        </Routes>
                    </main>
                    <FooterCard />
                </div>
            </Router>
        </AuthProvider>
    );
};

export default App;
