import React, { useState, useEffect } from 'react';
import '../../styles/DigitalClockCard.css';

const DigitalClockCard = () => {
    const [currentTime, setCurrentTime] = useState(new Date());

    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentTime(new Date());
        }, 1000);
        return () => clearInterval(intervalId);
    }, []);

    // Format the date and time for display
    const dateOptions = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    const formattedDate = currentTime.toLocaleDateString(undefined, dateOptions);
    const formattedTime = currentTime.toLocaleTimeString();

    return (
        <div className="digital-clock-card">
            <div className="clock-header" title="Current Date and Time">
                Current Date &amp; Time
            </div>
            <div className="clock-content">
                <div className="clock-date">{formattedDate}</div>
                <div className="clock-time">{formattedTime}</div>
            </div>
        </div>
    );
};

export default DigitalClockCard;
