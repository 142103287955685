import React, { useState } from 'react';
import '../../styles/FooterCard.css';
import { submitContactMessage } from '../../services/api';

const FooterCard = () => {
    const [contactForm, setContactForm] = useState({
        name: '',
        email: '',
        message: '',
    });
    const [showContactForm, setShowContactForm] = useState(false);
    const [submissionStatus, setSubmissionStatus] = useState(null);

    const handleChange = (e) => {
        setContactForm({
            ...contactForm,
            [e.target.name]: e.target.value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setSubmissionStatus('submitting');

        try {
            const response = await submitContactMessage(contactForm);
            console.log('Message submitted:', response.data.message);
            setSubmissionStatus('success');
            setContactForm({ name: '', email: '', message: '' });
            setTimeout(() => setSubmissionStatus(null), 19000);

        } catch (error) {
            console.error('Error submitting message:', error);
            setSubmissionStatus('error');
            setTimeout(() => setSubmissionStatus(null), 19000);
        }
    };

    const toggleContactForm = () => {
        setShowContactForm(!showContactForm);
        setSubmissionStatus(null);
    };

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    return (
        <div className="footer-card">
            <div className="footer-card-content">
                <div className="footer-info" title="Copyright Information">
                    <p>&copy; {new Date().getFullYear()} AITE. All rights reserved.</p>
                </div>
                <p>Do you have a question or want to talk to us? Contact us by clicking the button below.</p>
                <div className="footer-contact-toggle">
                <button onClick={toggleContactForm} className="footer-contact-toggle-button">
                        {showContactForm ? 'Hide Contact Form' : 'Contact Us'}
                    </button>
                </div>
                {showContactForm && (
                    <div className="footer-contact-form">
                        <h3>Contact Us</h3>
                        <p>Fill out this form and we will be in touch at our earliest convenience.</p>
                        <form onSubmit={handleSubmit}>
                            <input
                                type="text"
                                name="name"
                                placeholder="Your Name"
                                value={contactForm.name}
                                onChange={handleChange}
                                required
                            />
                            <input
                                type="email"
                                name="email"
                                placeholder="Your Email"
                                value={contactForm.email}
                                onChange={handleChange}
                                required
                            />
                            <textarea
                                name="message"
                                placeholder="Your Message"
                                value={contactForm.message}
                                onChange={handleChange}
                                required
                            />
                            <button type="submit" disabled={submissionStatus === 'submitting'}>
                                {submissionStatus === 'submitting' ? 'Submitting...' : 'Send Message'}
                            </button>
                        </form>
                        {submissionStatus === 'success' && (
                            <p className="success-message">Thank you for contacting us!. A member of our team will be in touch shortly.</p>
                        )}
                        {submissionStatus === 'error' && (
                            <p className="error-message">Error sending message. Please try again.</p>
                        )}
                    </div>
                )}
                <div className="footer-return-top">
                    <button onClick={scrollToTop} title="Return to Top">
                        Return to Top
                    </button>
                </div>
            </div>
        </div>
    );
};

export default FooterCard;
