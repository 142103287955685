import React, { useState, useEffect } from 'react';
import { fetchMeetings, fetchAttendanceAnalysis } from '../services/api';
import { Bar } from 'react-chartjs-2';
import 'chart.js/auto';
import '../styles/AttendanceAnalysis.css';

const AttendanceAnalysis = () => {
    const [meetings, setMeetings] = useState([]);
    const [selectedMeeting, setSelectedMeeting] = useState('');
    const [attendanceData, setAttendanceData] = useState(null);
    const [error, setError] = useState('');

    // Load meetings initially
    useEffect(() => {
        const loadMeetings = async () => {
            try {
                const response = await fetchMeetings();
                setMeetings(response.data);
            } catch (error) {
                console.error('Error fetching meetings:', error);
            }
        };
        loadMeetings();
    }, []);

    const handleMeetingChange = (event) => {
        setSelectedMeeting(event.target.value);
        setError('');
    };

    // Fetch attendance analysis every 5 seconds when a meeting is selected
    useEffect(() => {
        let intervalId;
        const fetchAnalysis = async () => {
            try {
                if (selectedMeeting) {
                    const response = await fetchAttendanceAnalysis(selectedMeeting);
                    setAttendanceData(response.data);
                    setError('');
                }
            } catch (error) {
                console.error('Error fetching attendance analysis:', error);
                if (
                    error.response &&
                    error.response.data &&
                    error.response.data.error === 'Not enough data for comparison'
                ) {
                    setAttendanceData({
                        meeting_names: [selectedMeeting],
                        attendance_counts: [
                            {
                                meeting_name: selectedMeeting,
                                meeting_date: '',
                                attendance_count: 0,
                            },
                        ],
                    });
                } else {
                    setError('Error fetching attendance analysis: ' + error.response.data.error);
                }
            }
        };

        if (selectedMeeting) {
            fetchAnalysis(); // initial fetch
            intervalId = setInterval(fetchAnalysis, 5000);
        }

        return () => clearInterval(intervalId);
    }, [selectedMeeting]);

    // Helper function to compute a trend line (linear regression) from attendance counts
    const computeTrendLine = (counts) => {
        const n = counts.length;
        if (n === 0) return [];
        let sumX = 0,
            sumY = 0,
            sumXY = 0,
            sumXX = 0;
        counts.forEach((y, i) => {
            sumX += i;
            sumY += y;
            sumXY += i * y;
            sumXX += i * i;
        });
        const slope = (n * sumXY - sumX * sumY) / (n * sumXX - sumX * sumX);
        const intercept = (sumY - slope * sumX) / n;
        let trendData = [];
        for (let i = 0; i < n; i++) {
            trendData.push(intercept + slope * i);
        }
        return trendData;
    };

    // Compute insights: average, highest, lowest, and latest attendance
    const averageAttendance =
        attendanceData && attendanceData.attendance_counts.length > 0
            ? attendanceData.attendance_counts.reduce((sum, ac) => sum + ac.attendance_count, 0) /
              attendanceData.attendance_counts.length
            : 0;
    const highestAttendance =
        attendanceData && attendanceData.attendance_counts.length > 0
            ? Math.max(...attendanceData.attendance_counts.map(ac => ac.attendance_count))
            : 0;
    const lowestAttendance =
        attendanceData && attendanceData.attendance_counts.length > 0
            ? Math.min(...attendanceData.attendance_counts.map(ac => ac.attendance_count))
            : 0;
    // Determine the latest attendance based on the most recent meeting_date
    const latestAttendanceObj =
        attendanceData && attendanceData.attendance_counts.length > 0
            ? attendanceData.attendance_counts.reduce((latest, current) => {
                  // If the current object has a valid meeting_date and it is later than the latest so far, update
                  if (
                      current.meeting_date &&
                      (!latest.meeting_date || new Date(current.meeting_date) > new Date(latest.meeting_date))
                  ) {
                      return current;
                  }
                  return latest;
              }, attendanceData.attendance_counts[0])
            : null;
    const latestAttendance = latestAttendanceObj ? latestAttendanceObj.attendance_count : 0;

    const barData = attendanceData ? attendanceData.attendance_counts.map(ac => ac.attendance_count) : [];
    const trendData =
        attendanceData && attendanceData.attendance_counts.length > 1 ? computeTrendLine(barData) : [];

    const data = {
        labels: attendanceData ? attendanceData.meeting_names : [],
        datasets: [
            {
                type: 'bar',
                label: 'Total Attendance',
                data: barData,
                backgroundColor: 'rgba(75, 192, 192, 0.4)',
                borderColor: 'rgba(75, 192, 192, 1)',
                borderWidth: 2,
            },
            ...(trendData.length > 0
                ? [
                      {
                          type: 'line',
                          label: 'Trend Line',
                          data: trendData,
                          borderColor: 'rgba(255, 99, 132, 1)',
                          borderWidth: 2,
                          fill: false,
                          borderDash: [5, 5],
                          tension: 0,
                      },
                  ]
                : []),
        ],
    };

    const options = {
        responsive: true,
        plugins: {
            legend: {
                display: true,
                position: 'top',
            },
            tooltip: {
                callbacks: {
                    label: function (context) {
                        let label = context.dataset.label || '';
                        if (label) {
                            label += ': ';
                        }
                        label += context.parsed.y;
                        // If it's the bar dataset, append the meeting date if available
                        if (context.dataset.type === 'bar' && attendanceData) {
                            const idx = context.dataIndex;
                            const meetingInfo = attendanceData.attendance_counts[idx];
                            if (meetingInfo && meetingInfo.meeting_date) {
                                label += ` (Date: ${meetingInfo.meeting_date})`;
                            }
                        }
                        return label;
                    },
                },
            },
        },
        scales: {
            y: {
                beginAtZero: true,
                ticks: { stepSize: 5 },
            },
        },
    };

    return (
        <div className="attendance-analysis-container">
            <h2 className="attendance-analysis-header">Attendance Analysis</h2>
            <div className="attendance-analysis-select-container">
                <label className="attendance-analysis-label">Select Meeting:</label>
                <select
                    value={selectedMeeting}
                    onChange={handleMeetingChange}
                    className="attendance-analysis-select"
                >
                    <option value="">-- Select a Meeting --</option>
                    {meetings.map((meeting) => (
                        <option key={meeting.meeting_id} value={meeting.meeting_name}>
                            {meeting.meeting_name}
                        </option>
                    ))}
                </select>
            </div>
            {error && <p className="attendance-analysis-error">{error}</p>}
            <div className="attendance-analysis-chart">
                {attendanceData && <Bar data={data} options={options} />}
            </div>
            {attendanceData && (
                <div className="attendance-analysis-insights">
                    <p>Average Attendance: {averageAttendance.toFixed(2)}</p>
                    <p>Highest Attendance: {highestAttendance}</p>
                    <p>Lowest Attendance: {lowestAttendance}</p>
                    <p>Latest Attendance: {latestAttendance}</p>
                </div>
            )}
        </div>
    );
};

export default AttendanceAnalysis;
