import React, { useState, useEffect, useRef, useCallback } from 'react';
import { searchMembers, recordAttendance, fetchMeetings } from '../services/api';
//import GenerateQrCode from './GenerateQrCode';
import GenerateMultipleQrCodes from './GenerateMultipleQrCodes';
import '../styles/SearchMembers.css';
import moment from 'moment';

const SearchMembers = () => {
    const [query, setQuery] = useState('');
    const [results, setResults] = useState([]);
    const [selectedMembers, setSelectedMembers] = useState([]);
    const [meetings, setMeetings] = useState([]);
    const [selectedMeeting, setSelectedMeeting] = useState('');
    const [highlightedMembers, setHighlightedMembers] = useState([]);
    const inputRef = useRef(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const meetingsResponse = await fetchMeetings();
                setMeetings(meetingsResponse.data);
            } catch (error) {
                console.error('Error fetching meetings:', error);
            }
        };

        fetchData();
    }, []);

    const handleSearch = useCallback(async () => {
        try {
            const response = await searchMembers(query);
            setResults(response.data);
        } catch (error) {
            console.error('Error searching members:', error);
        }
    }, [query]);

    const handleKeyPress = useCallback((event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            handleSearch();
        }
    }, [handleSearch]);

    useEffect(() => {
        const inputElement = inputRef.current;

        if (inputElement) {
            inputElement.addEventListener('keypress', handleKeyPress);
        }

        return () => {
            if (inputElement) {
                inputElement.removeEventListener('keypress', handleKeyPress);
            }
        };
    }, [handleKeyPress]);

    const handleCheckboxChange = (memberId) => {
        setSelectedMembers((prevSelected) =>
            prevSelected.includes(memberId)
                ? prevSelected.filter((id) => id !== memberId)
                : [...prevSelected, memberId]
        );
    };

    const handleRecordAttendance = async () => {
        if (!selectedMeeting) {
            alert('Please select a meeting.');
            return;
        }

        try {
            const response = await recordAttendance(selectedMembers, selectedMeeting);
            alert(response.data.message);
            setHighlightedMembers(selectedMembers);
            setTimeout(() => setHighlightedMembers([]), 90000000);
            setSelectedMembers([]);
            setResults([]);
        } catch (error) {
            console.error('Error recording attendance:', error);
        }
    };

    const calculateTimeSinceLastCheckIn = (lastCheckIn) => {
        if (!lastCheckIn) return 'Never';

        const lastCheckInMoment = moment(lastCheckIn);
        const now = moment();
        const duration = moment.duration(now.diff(lastCheckInMoment));

        const weeks = Math.floor(duration.asWeeks());
        const days = Math.floor(duration.asDays()) % 7;
        const hours = duration.hours();
        const minutes = duration.minutes();

        let timeString = '';
        if (weeks > 0) timeString += `${weeks} week${weeks !== 1 ? 's' : ''}, `;
        if (days > 0) timeString += `${days} day${days !== 1 ? 's' : ''}, `;
        if (hours > 0) timeString += `${hours} hour${hours !== 1 ? 's' : ''}, `;
        if (minutes > 0) timeString += `${minutes} minute${minutes !== 1 ? 's' : ''}, `;

        return timeString.length > 0 ? timeString.slice(0, -2) + ' ago' : 'Just now';
    };

    return (
        <div className="search-members-container">
            <h2 className="title">Search and Record Members Attendance</h2>
            <div className="dropdown-container">
                <select
                    className="meeting-dropdown"
                    value={selectedMeeting}
                    onChange={(e) => setSelectedMeeting(e.target.value)}
                >
                    <option value="">Select Meeting</option>
                    {meetings.map((meeting) => (
                        <option key={meeting.meeting_id} value={meeting.meeting_id}>
                            {meeting.meeting_name}
                        </option>
                    ))}
                </select>
            </div>
            <div className="search-bar">
                <input
                    type="text"
                    className="search-input"
                    ref={inputRef}
                    value={query}
                    onChange={(e) => setQuery(e.target.value)}
                    placeholder="Enter partial surname"
                />
                <button className="search-button" onClick={handleSearch}>Search</button>
            </div>
            <div className="results-container">
                {results.map((member) => (
                    <div key={member.member_id} className={`member-item ${highlightedMembers.includes(member.member_id) ? 'highlight' : ''}`}>
                        <input
                            type="checkbox"
                            className="checkbox"
                            checked={selectedMembers.includes(member.member_id)}
                            onChange={() => handleCheckboxChange(member.member_id)}
                        />
                        <div className="member-label">
                            <span className="member-name">
                                {member.first_name} {member.middle_name} {member.last_name}
                            </span>
                            <span className="last-check-in">
                                (Last check-in: {member.last_check_in || 'Never'})
                            </span>
                            <span className="time-since-check-in">
                                (Time since last check-in: {calculateTimeSinceLastCheckIn(member.last_check_in)})
                            </span>
                            <span className="Meeting-Check-in">
                                (Meeting check-in: {member.meeting_name || "Never"})
                            </span>
                        </div>
                    </div>
                ))}
            </div>
            <button className="record-button" onClick={handleRecordAttendance}>Record Attendance</button>
            <GenerateMultipleQrCodes />
        </div>
    );
};

export default SearchMembers;
