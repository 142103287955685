// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Container styling */
.addmember-container {
    max-width: 600px;
    margin: 20px auto;
    padding: 20px;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

/* Heading */
.addmember-title {
    text-align: center;
    color: #333;
    margin-bottom: 20px;
}

/* Form Styling */
.addmember-form {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

/* Input and Select Fields */
.addmember-input-field,
.addmember-select-field {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

/* Input Group (e.g., for date fields with labels) */
.addmember-input-group {
    display: flex;
    flex-direction: column;
}

/* Submit Button */
.addmember-submit-button {
    padding: 10px;
    background: #28a745;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background 0.3s;
}

.addmember-submit-button:hover {
    background: #218838;
}

/* Responsive Design */
@media (max-width: 768px) {
    .addmember-container {
        max-width: 90%;
        padding: 15px;
    }
}
`, "",{"version":3,"sources":["webpack://./src/styles/AddMember.css"],"names":[],"mappings":"AAAA,sBAAsB;AACtB;IACI,gBAAgB;IAChB,iBAAiB;IACjB,aAAa;IACb,gBAAgB;IAChB,kBAAkB;IAClB,uCAAuC;AAC3C;;AAEA,YAAY;AACZ;IACI,kBAAkB;IAClB,WAAW;IACX,mBAAmB;AACvB;;AAEA,iBAAiB;AACjB;IACI,aAAa;IACb,sBAAsB;IACtB,SAAS;AACb;;AAEA,4BAA4B;AAC5B;;IAEI,WAAW;IACX,aAAa;IACb,sBAAsB;IACtB,kBAAkB;AACtB;;AAEA,oDAAoD;AACpD;IACI,aAAa;IACb,sBAAsB;AAC1B;;AAEA,kBAAkB;AAClB;IACI,aAAa;IACb,mBAAmB;IACnB,YAAY;IACZ,YAAY;IACZ,kBAAkB;IAClB,eAAe;IACf,2BAA2B;AAC/B;;AAEA;IACI,mBAAmB;AACvB;;AAEA,sBAAsB;AACtB;IACI;QACI,cAAc;QACd,aAAa;IACjB;AACJ","sourcesContent":["/* Container styling */\n.addmember-container {\n    max-width: 600px;\n    margin: 20px auto;\n    padding: 20px;\n    background: #fff;\n    border-radius: 8px;\n    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);\n}\n\n/* Heading */\n.addmember-title {\n    text-align: center;\n    color: #333;\n    margin-bottom: 20px;\n}\n\n/* Form Styling */\n.addmember-form {\n    display: flex;\n    flex-direction: column;\n    gap: 10px;\n}\n\n/* Input and Select Fields */\n.addmember-input-field,\n.addmember-select-field {\n    width: 100%;\n    padding: 10px;\n    border: 1px solid #ccc;\n    border-radius: 5px;\n}\n\n/* Input Group (e.g., for date fields with labels) */\n.addmember-input-group {\n    display: flex;\n    flex-direction: column;\n}\n\n/* Submit Button */\n.addmember-submit-button {\n    padding: 10px;\n    background: #28a745;\n    color: white;\n    border: none;\n    border-radius: 5px;\n    cursor: pointer;\n    transition: background 0.3s;\n}\n\n.addmember-submit-button:hover {\n    background: #218838;\n}\n\n/* Responsive Design */\n@media (max-width: 768px) {\n    .addmember-container {\n        max-width: 90%;\n        padding: 15px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
