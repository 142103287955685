import React, { useState, useEffect } from 'react';
import { addMember, fetchDepartments, fetchUnits, fetchTypes } from '../services/api';
import '../styles/AddMember.css';

const AddMember = () => {
    const [departments, setDepartments] = useState([]);
    const [units, setUnits] = useState([]);
    const [types, setTypes] = useState([]);
    
    const [formData, setFormData] = useState({
        first_name: '',
        last_name: '',
        email: '',
        phone_number: '',
        address: '',
        post_code: '',
        birthdate: '',
        unit_id: '',
        department_id: '',
        type_id: '',  // This will be updated later with the default value
        other_details: '',
        middle_name: '',
        city: '',
        relationship: '',
        gender: ''
    });

    useEffect(() => {
        const fetchData = async () => {
            const departmentsResult = await fetchDepartments();
            setDepartments(departmentsResult.data);
            
            const unitsResult = await fetchUnits();
            setUnits(unitsResult.data);
            
            const typesResult = await fetchTypes();
            setTypes(typesResult.data);

            // Set "First Timer" as the default type_id
            const defaultType = typesResult.data.find(type => type.type_name === "First Timer");
            if (defaultType) {
                setFormData(prevFormData => ({
                    ...prevFormData,
                    type_id: defaultType.type_id
                }));
            }
        };

        fetchData();
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        await addMember(formData);
        setFormData({
            first_name: '',
            last_name: '',
            email: '',
            phone_number: '',
            address: '',
            post_code: '',
            birthdate: '',
            unit_id: '',
            department_id: '',
            type_id: '',  // Reset to empty, will be set again by useEffect
            other_details: '',
            middle_name: '',
            city: '',
            relationship: '',
            gender: ''
        });
    };

    return (
        <div className="addmember-container">
            <h2 className="addmember-title">Add Member</h2>
            <form onSubmit={handleSubmit} className="addmember-form">
                <input 
                    type="text" 
                    name="first_name" 
                    value={formData.first_name} 
                    onChange={handleChange} 
                    placeholder="First Name" 
                    required 
                    className="addmember-input-field" 
                />
                <input 
                    type="text" 
                    name="last_name" 
                    value={formData.last_name} 
                    onChange={handleChange} 
                    placeholder="Last Name" 
                    required 
                    className="addmember-input-field" 
                />
                <input 
                    type="text" 
                    name="middle_name" 
                    value={formData.middle_name} 
                    onChange={handleChange} 
                    placeholder="Middle Name" 
                    className="addmember-input-field" 
                />
                <input 
                    type="email" 
                    name="email" 
                    value={formData.email} 
                    onChange={handleChange} 
                    placeholder="Email" 
                    required 
                    className="addmember-input-field" 
                />
                <input 
                    type="text" 
                    name="phone_number" 
                    value={formData.phone_number} 
                    onChange={handleChange} 
                    placeholder="Phone Number" 
                    required 
                    className="addmember-input-field" 
                />
                <input 
                    type="text" 
                    name="address" 
                    value={formData.address} 
                    onChange={handleChange} 
                    placeholder="Address" 
                    required 
                    className="addmember-input-field" 
                />
                <input 
                    type="text" 
                    name="post_code" 
                    value={formData.post_code} 
                    onChange={handleChange} 
                    placeholder="Post Code for your address" 
                    required 
                    className="addmember-input-field" 
                />
                <div className="addmember-input-group">
                    <label htmlFor="birthdate">Birthdate:</label>
                    <input 
                        type="date" 
                        name="birthdate" 
                        value={formData.birthdate} 
                        onChange={handleChange} 
                        placeholder="Please enter your birth date" 
                        required 
                        className="addmember-input-field" 
                    />
                </div>
                <select 
                    name="department_id" 
                    value={formData.department_id} 
                    onChange={handleChange} 
                    required 
                    className="addmember-select-field"
                >
                    <option value="">Select Department</option>
                    {departments.map((department) => (
                        <option key={department.department_id} value={department.department_id}>
                            {department.department_name}
                        </option>
                    ))}
                </select>
                <select 
                    name="unit_id" 
                    value={formData.unit_id} 
                    onChange={handleChange} 
                    required 
                    className="addmember-select-field"
                >
                    <option value="">Select Unit</option>
                    {units.map((unit) => (
                        <option key={unit.unit_id} value={unit.unit_id}>
                            {unit.unit_name}
                        </option>
                    ))}
                </select>
                <select 
                    name="type_id" 
                    value={formData.type_id} 
                    onChange={handleChange} 
                    required 
                    className="addmember-select-field"
                >
                    <option value="">Select Type</option>
                    {types.map((type) => (
                        <option key={type.type_id} value={type.type_id}>
                            {type.type_name}
                        </option>
                    ))}
                </select>  
                <input 
                    type="text" 
                    name="Any prayer request?" 
                    value={formData.other_details} 
                    onChange={handleChange} 
                    placeholder="Please type any prayer request you have here." 
                    className="addmember-input-field" 
                />
                <input 
                    type="text" 
                    name="city" 
                    value={formData.city} 
                    onChange={handleChange} 
                    placeholder="City" 
                    required 
                    className="addmember-input-field" 
                />
                <select 
                    name="relationship" 
                    value={formData.relationship} 
                    onChange={handleChange} 
                    required 
                    className="addmember-select-field"
                >
                    <option value="">Select Relationship</option>
                    <option value="Head">Head</option>
                    <option value="Spouse">Spouse</option>
                    <option value="Child">Child</option>
                    <option value="Other">Other</option>
                </select>
                <select 
                    name="gender" 
                    value={formData.gender} 
                    onChange={handleChange} 
                    required 
                    className="addmember-select-field"
                >
                    <option value="">Select Gender</option>
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                    <option value="Prefer not to say">Prefer not to say</option>
                </select>
                <button type="submit" className="addmember-submit-button">Add Member</button>
            </form>
        </div>
    );
};

export default AddMember;
