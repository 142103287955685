// src/api.js
import axios from 'axios';

const API_URL = 'https://ml.aitogoodeffects.com/api';

// Create an axios instance
const api = axios.create({
    baseURL: API_URL,
});

// Add a request interceptor to include the token
api.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('token'); // Adjust based on where you store the token
        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

export const fetchMembers = () => api.get('/members');
export const addMember = (member) => api.post('/members', member);
export const fetchMeetings = () => api.get('/meetings');
export const searchMembers = (query) => api.get('/search_members', { params: { q: query } });
export const recordAttendance = (memberIds, meetingId) => api.post('/record_attendance', { member_ids: memberIds, meeting_id: meetingId });
export const fetchDepartments = () => api.get('/departments');
export const fetchUnits = () => api.get('/units');
export const fetchTypes = () => api.get('/types');
export const fetchAttendanceAnalysis = (meetingName) => api.get('/attendance_analysis', { params: { meeting_name: meetingName } });
export const fetchMultiLevelAnalysis = (meetingName, period) => api.get('/multi_level_analysis', { params: { meeting_name: meetingName, period: period } });
export const fetchAttendanceStatuses = () => api.get('/attendance_statuses');
export const fetchFilteredData = (meetingId, typeId, status) => api.get('/filtered_data', { params: { meeting_id: meetingId, type_id: typeId, status: status } });
export const fetchMemberById = (memberId) => api.get(`/members/${memberId}`);
export const updateMember = (memberId, memberData) => api.put(`/update_member/${memberId}`, memberData);
export const generateQrCode = (memberId) => `${API_URL}/generate_qr/${memberId}`;

export const qrCheckIn = (memberId, meetingId) => {
    const payload = {
        member_id: parseInt(memberId, 10),
        meeting_id: parseInt(meetingId, 10),
    };
    return api.post('/qr_checkin', payload);
};

{/*
export const fetchMembers_reg = (page, perPage) => {
    return api.get('/members_reg', {
        params: {
            page: page,
            per_page: perPage,
        },
    });
};
*/}

{/*

export const fetchMembers_reg = (page, perPage, meetingId) => {
    return api.get('/members_reg', {
        params: {
            page: page,
            per_page: perPage,
            meeting_id: meetingId,  // Optional filter
        },
    });
};

*/}

export const fetchMembers_reg = (page, perPage, meetingId, typeId, departmentId, unitId, sortBy, sortOrder) => {
    return api.get('/members_reg', {
        params: {
            page: page,
            per_page: perPage,
            meeting_id: meetingId,  // Optional filter by meeting
            type_id: typeId,  // Optional filter by type
            department_id: departmentId,  // Optional filter by department
            unit_id: unitId,  // Optional filter by unit
            sort_by: sortBy,  // Sorting field
            sort_order: sortOrder  // Sorting order (asc/desc)
        },
    });
};

export const submitContactMessage = (messageData) => {
    return api.post('/contact_us', messageData);  // POST to /contact_us
};

export const fetchContactMessages = () => {
    return api.get('/contact_us'); // GET from /contact_us
};

export const registerUser = (user) => api.post('/register', user);
export const loginUser = (credentials) => api.post('/login', credentials);
export const logoutUser = () => api.get('/logout');
