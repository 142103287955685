// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.digital-clock-card {
    background: linear-gradient(135deg, #4facfe 0%, #00f2fe 100%);
    color: #ffffff;
    border-radius: 12px;
    padding: 20px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    text-align: center;
    max-width: 350px;
    margin: 20px auto;
    transition: transform 0.3s ease;
}

.digital-clock-card:hover {
    transform: scale(1.03);
}

.clock-header {
    font-size: 1.5rem;
    margin-bottom: 15px;
    font-weight: 600;
    text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2);
}

.clock-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.clock-date {
    font-size: 1.2rem;
    margin-bottom: 10px;
}

.clock-time {
    font-size: 2rem;
    font-weight: bold;
}

/* Responsive Styling */

/* Mobile devices */
@media (max-width: 600px) {
    .digital-clock-card {
        max-width: 90%;
        padding: 15px;
    }
    .clock-header {
        font-size: 1.3rem;
    }
    .clock-time {
        font-size: 1.8rem;
    }
}

/* Tablets */
@media (min-width: 601px) and (max-width: 900px) {
    .digital-clock-card {
        max-width: 80%;
    }
}
`, "",{"version":3,"sources":["webpack://./src/styles/DigitalClockCard.css"],"names":[],"mappings":"AAAA;IACI,6DAA6D;IAC7D,cAAc;IACd,mBAAmB;IACnB,aAAa;IACb,yCAAyC;IACzC,kBAAkB;IAClB,gBAAgB;IAChB,iBAAiB;IACjB,+BAA+B;AACnC;;AAEA;IACI,sBAAsB;AAC1B;;AAEA;IACI,iBAAiB;IACjB,mBAAmB;IACnB,gBAAgB;IAChB,2CAA2C;AAC/C;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;AAC3B;;AAEA;IACI,iBAAiB;IACjB,mBAAmB;AACvB;;AAEA;IACI,eAAe;IACf,iBAAiB;AACrB;;AAEA,uBAAuB;;AAEvB,mBAAmB;AACnB;IACI;QACI,cAAc;QACd,aAAa;IACjB;IACA;QACI,iBAAiB;IACrB;IACA;QACI,iBAAiB;IACrB;AACJ;;AAEA,YAAY;AACZ;IACI;QACI,cAAc;IAClB;AACJ","sourcesContent":[".digital-clock-card {\n    background: linear-gradient(135deg, #4facfe 0%, #00f2fe 100%);\n    color: #ffffff;\n    border-radius: 12px;\n    padding: 20px;\n    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);\n    text-align: center;\n    max-width: 350px;\n    margin: 20px auto;\n    transition: transform 0.3s ease;\n}\n\n.digital-clock-card:hover {\n    transform: scale(1.03);\n}\n\n.clock-header {\n    font-size: 1.5rem;\n    margin-bottom: 15px;\n    font-weight: 600;\n    text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2);\n}\n\n.clock-content {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n}\n\n.clock-date {\n    font-size: 1.2rem;\n    margin-bottom: 10px;\n}\n\n.clock-time {\n    font-size: 2rem;\n    font-weight: bold;\n}\n\n/* Responsive Styling */\n\n/* Mobile devices */\n@media (max-width: 600px) {\n    .digital-clock-card {\n        max-width: 90%;\n        padding: 15px;\n    }\n    .clock-header {\n        font-size: 1.3rem;\n    }\n    .clock-time {\n        font-size: 1.8rem;\n    }\n}\n\n/* Tablets */\n@media (min-width: 601px) and (max-width: 900px) {\n    .digital-clock-card {\n        max-width: 80%;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
