// src/App.js
import React, { useContext } from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import AddMember from './components/AddMember';
import SearchMembers from './components/SearchMembers';
import AttendanceAnalysis from './components/AttendanceAnalysis';
import MultiLevelAnalysis from './components/MultiLevelAnalysis';
import FilteredData from './components/FilteredData';
import UpdateMember from './components/UpdateMember';
import QrCheckIn from './components/QrCheckIn';
import MemberList from './components/MemberList';
import Register from './components/Register';
import Login from './components/Login';
import ProtectedRoute from './components/ProtectedRoute';
import { AuthProvider, AuthContext } from './context/AuthContext';
import DigitalClockCard from './components/cards/DigitalClockCard';
import FooterCard from './components/cards/FooterCard';
import './styles/styles.css';

const App = () => {
    const { user, logout } = useContext(AuthContext);
    
    const handleLogout = async () => {
        await logout();
    };

    return (
        <AuthProvider>
            <Router>
                <div className="App">
                    <header className="App-header">

                        <h1>Welcome to Real Time Analytics</h1>
			<div className="App">
            			<DigitalClockCard />
      		      </div>
                        <nav>
                            <ul className='App-header-navigation'>
                                {user ? (
                                    <>
                                   <button type='button'>    <li><Link to="/">Search Members</Link></li> </button>
                                   <button type='button'>     <li><Link to="/addmember">Add Member to Database</Link></li></button>
                                   <button type='button'>     <li><Link to="/analysis">Attendance Analysis</Link></li> </button>
                                   <button type='button'>     <li><Link to="/multilevelanalysis">MultiLevel Attendance Analysis</Link></li> </button>
                                 {/*  <button type='button'>     <li><Link to="/followup">Follow Up On Members</Link></li> </button> */}
                                   <button type='button'>      <li><Link to="/members">Member List</Link></li> </button>
                                   <button type='button'>      <li><Link to="/update">Update Details</Link></li> </button>
                                   <button type='button'>      <li><Link to="/qrcheckin">QR Code Check-In</Link></li> </button>
                                   <button type='button'>      <li><button onClick={handleLogout}>Logout</button></li> </button>
                                    </>
                                ) : (
                                    <>
                                   <button type='button'>      <li><Link to="/login">Login</Link></li> </button>
                                     {/*  <li><Link to="/register">Register</Link></li> */}
				  <button type='button'>  <li><Link to="/addmember">Register today</Link></li> </button>
                                    </>
                                )}
                            </ul>
                        </nav>
                    </header>
                    <main>
                        <Routes>
                            <Route path="/login" element={<Login />} />
                        {/*   <Route path="/register" element={<Register />} /> */}
				<Route path="/addmember" element={<AddMember />} />
                            <Route
                                path="/"
                                element={
                                    <ProtectedRoute>
                                        <SearchMembers />
                                    </ProtectedRoute>
                                }
                            />
                            {/*<Route
                                path="/addmember"
                                element={
                                    <ProtectedRoute>
                                        <AddMember />
                                    </ProtectedRoute>
                                }
                            />*/}
                            <Route
                                path="/analysis"
                                element={
                                    <ProtectedRoute>
                                        <AttendanceAnalysis />
                                    </ProtectedRoute>
                                }
                            />
                            <Route
                                path="/multilevelanalysis"
                                element={
                                    <ProtectedRoute>
                                        <MultiLevelAnalysis />
                                    </ProtectedRoute>
                                }
                            />
                          {/*  <Route
                                path="/followup"
                                element={
                                    <ProtectedRoute>
                                        <FilteredData />
                                    </ProtectedRoute>
                                }
                            /> */}
                            <Route
                                path="/members"
                                element={
                                    <ProtectedRoute>
                                        <MemberList />
                                    </ProtectedRoute>
                                }
                            />
                            <Route
                                path="/update"
                                element={
                                    <ProtectedRoute>
                                        <UpdateMember />
                                    </ProtectedRoute>
                                }
                            />
                            <Route
                                path="/qrcheckin"
                                element={
                                    <ProtectedRoute>
                                        <QrCheckIn />
                                    </ProtectedRoute>
                                }
                            />
                        </Routes>
                    </main>
		<FooterCard />
                </div>
            </Router>
        </AuthProvider>
    );
};

export default App;
