import React, { useState, useEffect, useRef } from 'react';
import { qrCheckIn, fetchMeetings } from '../services/api';
import AttendanceAnalysis from './AttendanceAnalysis';
import '../styles/QrCheckIn.css';

const QrCheckIn = () => {
    const [message, setMessage] = useState('');
    const [meetings, setMeetings] = useState([]);
    const [selectedMeeting, setSelectedMeeting] = useState('');
    const meetingRef = useRef(selectedMeeting);
    let inputBuffer = '';

    // Synchronize the ref with the selectedMeeting state
    useEffect(() => {
        meetingRef.current = selectedMeeting;
    }, [selectedMeeting]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const meetingsResponse = await fetchMeetings();
                setMeetings(meetingsResponse.data);
            } catch (error) {
                console.error('Error fetching meetings:', error);
                setMessage('Failed to load meetings.');
            }
        };

        fetchData();

        // Listen for QR scanner input (acting as a keyboard)
        const handleScan = (event) => {
            if (event.key === 'Enter') {
                console.log('Received Enter key. Processing scanned data...');
                processScannedData(inputBuffer.trim());
                inputBuffer = '';
            } else {
                inputBuffer += event.key;
            }
        };

        document.addEventListener('keydown', handleScan);
        return () => document.removeEventListener('keydown', handleScan);
    }, []);

    const processScannedData = async (data) => {
        console.log('Scanned Data:', data);
        console.log('Selected Meeting:', meetingRef.current);

        if (!meetingRef.current) {
            setMessage('Please select a meeting before scanning.');
            console.log('No meeting selected. Aborting process.');
            return;
        }

        const digitsRegex = /^\d+$/;
        if (!digitsRegex.test(data)) {
            console.log('Regex match failed for scanned data:', data);
            setMessage('Invalid QR code format.');
            return;
        }

        const memberIdNumber = parseInt(data, 10);
        console.log('Extracted Member ID:', memberIdNumber);

        try {
            const response = await qrCheckIn(memberIdNumber, parseInt(meetingRef.current, 10));
            setMessage(response.data.message);
        } catch (error) {
            if (error.response) {
                console.error('API Error:', error.response.data);
                setMessage(error.response.data.error || 'Error checking in with QR code');
            } else {
                console.error('Unexpected Error:', error);
                setMessage('Unexpected error occurred during check-in.');
            }
        }
    };

    return (
        <div className="qr-checkin-container">
            <h2 className="qr-checkin-header">QR Code Check-In</h2>
            <div
                className="qr-checkin-select-container"
                title="Select a meeting from the list before scanning"
            >
                <select
                    className="qr-checkin-select"
                    value={selectedMeeting}
                    onChange={(e) => setSelectedMeeting(e.target.value)}
                >
                    <option value="">Select Meeting</option>
                    {meetings.map((meeting) => (
                        <option key={meeting.meeting_id} value={meeting.meeting_id}>
                            {meeting.meeting_name}
                        </option>
                    ))}
                </select>
            </div>
            <p className="qr-checkin-message" title="Status message for check-in">{message}</p>
           {/* <div className="qr-checkin-attendance-analysis-container">
                <AttendanceAnalysis />
            </div> */}
        </div>
    );
};

export default QrCheckIn;
