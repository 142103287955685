// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.qr-checkin-container {
    max-width: 800px;
    margin: 20px auto;
    padding: 20px;
    background: #ffffff;
    border-radius: 8px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.qr-checkin-header {
    font-size: 1.8rem;
    text-align: center;
    color: #333333;
    margin-bottom: 20px;
}

.qr-checkin-select-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
}

.qr-checkin-select {
    padding: 10px 15px;
    border: 1px solid #cccccc;
    border-radius: 4px;
    font-size: 1rem;
    width: 250px;
    transition: border 0.3s ease;
}

.qr-checkin-select:focus {
    border-color: #4bc0c0;
    outline: none;
}

.qr-checkin-message {
    text-align: center;
    font-size: 1.1rem;
    margin: 10px 0;
    color: #006400;
    font-weight: bold;
}

.qr-checkin-attendance-analysis-container {
    margin-top: 20px;
}
`, "",{"version":3,"sources":["webpack://./src/styles/QrCheckIn.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,iBAAiB;IACjB,aAAa;IACb,mBAAmB;IACnB,kBAAkB;IAClB,0CAA0C;IAC1C,4DAA4D;AAChE;;AAEA;IACI,iBAAiB;IACjB,kBAAkB;IAClB,cAAc;IACd,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,mBAAmB;AACvB;;AAEA;IACI,kBAAkB;IAClB,yBAAyB;IACzB,kBAAkB;IAClB,eAAe;IACf,YAAY;IACZ,4BAA4B;AAChC;;AAEA;IACI,qBAAqB;IACrB,aAAa;AACjB;;AAEA;IACI,kBAAkB;IAClB,iBAAiB;IACjB,cAAc;IACd,cAAc;IACd,iBAAiB;AACrB;;AAEA;IACI,gBAAgB;AACpB","sourcesContent":[".qr-checkin-container {\n    max-width: 800px;\n    margin: 20px auto;\n    padding: 20px;\n    background: #ffffff;\n    border-radius: 8px;\n    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);\n    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;\n}\n\n.qr-checkin-header {\n    font-size: 1.8rem;\n    text-align: center;\n    color: #333333;\n    margin-bottom: 20px;\n}\n\n.qr-checkin-select-container {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    margin-bottom: 20px;\n}\n\n.qr-checkin-select {\n    padding: 10px 15px;\n    border: 1px solid #cccccc;\n    border-radius: 4px;\n    font-size: 1rem;\n    width: 250px;\n    transition: border 0.3s ease;\n}\n\n.qr-checkin-select:focus {\n    border-color: #4bc0c0;\n    outline: none;\n}\n\n.qr-checkin-message {\n    text-align: center;\n    font-size: 1.1rem;\n    margin: 10px 0;\n    color: #006400;\n    font-weight: bold;\n}\n\n.qr-checkin-attendance-analysis-container {\n    margin-top: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
