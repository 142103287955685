// GenerateMultipleQrCodes.js
import React, { useState, useEffect, useRef, useCallback } from 'react';
import { searchMembers, fetchMeetings, generateQrCode } from '../services/api';
import moment from 'moment';
import '../styles/SearchMembers.css';

const GenerateMultipleQrCodes = () => {
    const [query, setQuery] = useState('');
    const [results, setResults] = useState([]);
    const [selectedMembers, setSelectedMembers] = useState([]);
    const [qrCodes, setQrCodes] = useState([]); // Array of objects { memberId, qrUrl, member }
    const inputRef = useRef(null);

    // Optional: Meeting dropdown for future enhancements
    const [meetings, setMeetings] = useState([]);
    const [selectedMeeting, setSelectedMeeting] = useState('');

    useEffect(() => {
        const fetchData = async () => {
            try {
                const meetingsResponse = await fetchMeetings();
                setMeetings(meetingsResponse.data);
            } catch (error) {
                console.error('Error fetching meetings:', error);
            }
        };

        fetchData();
    }, []);

    const handleSearch = useCallback(async () => {
        try {
            const response = await searchMembers(query);
            setResults(response.data);
        } catch (error) {
            console.error('Error searching members:', error);
        }
    }, [query]);

    const handleKeyPress = useCallback(
        (event) => {
            if (event.key === 'Enter') {
                event.preventDefault();
                handleSearch();
            }
        },
        [handleSearch]
    );

    useEffect(() => {
        const inputElement = inputRef.current;
        if (inputElement) {
            inputElement.addEventListener('keypress', handleKeyPress);
        }
        return () => {
            if (inputElement) {
                inputElement.removeEventListener('keypress', handleKeyPress);
            }
        };
    }, [handleKeyPress]);

    const handleCheckboxChange = (memberId) => {
        setSelectedMembers((prevSelected) =>
            prevSelected.includes(memberId)
                ? prevSelected.filter((id) => id !== memberId)
                : [...prevSelected, memberId]
        );
    };

    // Generate QR codes for each selected member and reset search state afterwards.
    const handleGenerateQrCodes = async () => {
        if (selectedMembers.length === 0) {
            alert('Please select at least one member.');
            return;
        }

        const generatedQrCodes = [];

        for (const memberId of selectedMembers) {
            try {
                // Assume generateQrCode returns a URL or string pointing to the QR image.
                const qrUrl = generateQrCode(memberId);
                // Retrieve member details from the current results list for display purposes.
                const member = results.find((m) => m.member_id === memberId);
                generatedQrCodes.push({ memberId, qrUrl, member });
            } catch (error) {
                console.error(`Error generating QR for member ${memberId}:`, error);
            }
        }

        setQrCodes(generatedQrCodes);
        alert('Selected member QR code generated successfully.');
        // Reset search query, results, and selected checkboxes for a new search.
        setResults([]);
        setQuery('');
        setSelectedMembers([]);
    };

    const calculateTimeSinceLastCheckIn = (lastCheckIn) => {
        if (!lastCheckIn) return 'Never';

        const lastCheckInMoment = moment(lastCheckIn);
        const now = moment();
        const duration = moment.duration(now.diff(lastCheckInMoment));

        const weeks = Math.floor(duration.asWeeks());
        const days = Math.floor(duration.asDays()) % 7;
        const hours = duration.hours();
        const minutes = duration.minutes();

        let timeString = '';
        if (weeks > 0) timeString += `${weeks} week${weeks !== 1 ? 's' : ''}, `;
        if (days > 0) timeString += `${days} day${days !== 1 ? 's' : ''}, `;
        if (hours > 0) timeString += `${hours} hour${hours !== 1 ? 's' : ''}, `;
        if (minutes > 0) timeString += `${minutes} minute${minutes !== 1 ? 's' : ''}, `;

        return timeString.length > 0 ? timeString.slice(0, -2) + ' ago' : 'Just now';
    };

    return (
        <div className="search-members-container">
            <h2 className="title">Search and Generate QR Codes for Members</h2>
{/*            {meetings.length > 0 && (
                <div className="dropdown-container">
                    <select
                        className="meeting-dropdown"
                        value={selectedMeeting}
                        onChange={(e) => setSelectedMeeting(e.target.value)}
                    >
                        <option value="">Select Meeting</option>
                        {meetings.map((meeting) => (
                            <option key={meeting.meeting_id} value={meeting.meeting_id}>
                                {meeting.meeting_name}
                            </option>
                        ))}
                    </select>
                </div>
            )} */}
            <div className="search-bar">
                <input
                    type="text"
                    className="search-input"
                    ref={inputRef}
                    value={query}
                    onChange={(e) => setQuery(e.target.value)}
                    placeholder="Enter partial surname"
                />
                <button className="search-button" onClick={handleSearch}>
                    Search
                </button>
            </div>
            <div className="results-container">
                {results.map((member) => (
                    <div key={member.member_id} className="member-item">
                        <input
                            type="checkbox"
                            className="checkbox"
                            checked={selectedMembers.includes(member.member_id)}
                            onChange={() => handleCheckboxChange(member.member_id)}
                        />
                        <div className="member-label">
                            <span className="member-name">
                                {member.first_name} {member.middle_name} {member.last_name}
                            </span>
                            <span className="last-check-in">
                                (Last check-in: {member.last_check_in || 'Never'})
                            </span>
                            <span className="time-since-check-in">
                                (Time since last check-in: {calculateTimeSinceLastCheckIn(member.last_check_in)})
                            </span>
                            <span className="Meeting-Check-in">
                                (Meeting check-in: {member.meeting_name || 'Never'})
                            </span>
                        </div>
                    </div>
                ))}
            </div>
            <button className="record-button" onClick={handleGenerateQrCodes}>
                Generate QR Codes
            </button>
            <div className="qr-codes-container">
                {qrCodes.map(({ memberId, qrUrl, member }) => (
                    <div key={memberId} className="qr-code-item">
                        <h3>
                            {member
                                ? `${member.first_name} ${member.last_name} (ID: ${memberId})`
                                : `Member ID: ${memberId}`}
                        </h3>
                        <img src={qrUrl} alt={`QR Code for ${memberId}`} />
                    </div>
                ))}
            </div>
        </div>
    );
};

export default GenerateMultipleQrCodes;
