import React, { useState, useEffect } from 'react';
import { fetchMeetings, fetchMultiLevelAnalysis } from '../services/api';
import { Line, Bar, Pie } from 'react-chartjs-2';
import 'chart.js/auto';
import '../styles/MultiLevelAnalysis.css';

const MultiLevelAnalysis = () => {
  const [meetings, setMeetings] = useState([]);
  const [selectedMeeting, setSelectedMeeting] = useState('');
  const [selectedPeriod, setSelectedPeriod] = useState('');
  const [analysisData, setAnalysisData] = useState(null);
  const [error, setError] = useState('');
  const [expandedChart, setExpandedChart] = useState(null); // 'bar' | 'line' | 'pie' or null

  const periods = ['week', 'month', 'year', 'year_to_date', '3_months', '6_months', 'all_time'];

  useEffect(() => {
    const loadMeetings = async () => {
      try {
        const response = await fetchMeetings();
        setMeetings(response.data);
      } catch (error) {
        console.error('Error fetching meetings:', error);
      }
    };
    loadMeetings();
  }, []);

  const handleMeetingChange = (event) => {
    setSelectedMeeting(event.target.value);
    setError('');
  };

  const handlePeriodChange = (event) => {
    setSelectedPeriod(event.target.value);
    setError('');
  };

  useEffect(() => {
    const fetchAnalysis = async () => {
      try {
        const response = await fetchMultiLevelAnalysis(selectedMeeting, selectedPeriod);
        setAnalysisData(response.data);
        setError('');
      } catch (error) {
        console.error('Error fetching multi-level analysis:', error);
        setError(
          'Error fetching multi-level analysis: ' +
            (error.response ? error.response.data.error : error.message)
        );
      }
    };

    if (selectedMeeting && selectedPeriod) {
      fetchAnalysis();
    }
  }, [selectedMeeting, selectedPeriod]);

  const data = {
    labels: analysisData ? analysisData.periods : [],
    datasets: [
      {
        label: 'Total Attendance for the Selected Period',
        data: analysisData ? analysisData.attendance_counts : [],
        backgroundColor: 'rgba(60, 175, 45, 0.37)',
        borderColor: 'rgb(40, 183, 104)',
        borderWidth: 4,
      },
    ],
  };

  // Render the appropriate chart component based on type
  const renderChart = (chartType) => {
    switch (chartType) {
      case 'bar':
        return <Bar data={data} options={chartOptions} />;
      case 'line':
        return <Line data={data} options={chartOptions} />;
      case 'pie':
        return <Pie data={data} options={chartOptions} />;
      default:
        return null;
    }
  };

  const chartOptions = {
    responsive: true,
    plugins: {
      legend: {
        display: true,
        position: 'top',
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            let label = context.dataset.label || '';
            if (label) {
              label += ': ';
            }
            label += context.parsed.y;
            // If it's the bar dataset, append the meeting date if available
            if (context.dataset.type === 'bar' && analysisData) {
              const idx = context.dataIndex;
              const meetingInfo = analysisData.attendance_counts[idx];
              if (meetingInfo && meetingInfo.meeting_date) {
                label += ` (Date: ${meetingInfo.meeting_date})`;
              }
            }
            return label;
          },
        },
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        ticks: { stepSize: 5 },
      },
    },
  };

  return (
    <div className="multi-level-analysis-container">
      <h2 className="mla-heading">Multi-Level Analysis</h2>
      <div className="mla-controls">
        <div className="mla-control">
          <label className="mla-label">Select Meeting:</label>
          <select className="mla-select" value={selectedMeeting} onChange={handleMeetingChange}>
            <option value="">-- Select a Meeting --</option>
            {meetings.map((meeting) => (
              <option key={meeting.meeting_id} value={meeting.meeting_name}>
                {meeting.meeting_name}
              </option>
            ))}
          </select>
        </div>
        <div className="mla-control">
          <label className="mla-label">Select Period:</label>
          <select className="mla-select" value={selectedPeriod} onChange={handlePeriodChange}>
            <option value="">-- Select a Period --</option>
            {periods.map((period) => (
              <option key={period} value={period}>
                {period.replace('_', ' ')}
              </option>
            ))}
          </select>
        </div>
      </div>
      {error && <p className="mla-error">{error}</p>}
      {analysisData && (
        <div className="mla-dashboard">
          {/* Bar Chart */}
          <div className="mla-chart-box">
            <h3>Bar Chart</h3>
            <button
              className="mla-expand-btn"
              onClick={() => setExpandedChart('bar')}
              title="Expand Chart"
            >
              ⤢
            </button>
            <div className="mla-chart">
              <Bar data={data} options={chartOptions} />
            </div>
          </div>
          {/* Line Chart */}
          <div className="mla-chart-box">
            <h3>Line Chart</h3>
            <button
              className="mla-expand-btn"
              onClick={() => setExpandedChart('line')}
              title="Expand Chart"
            >
              ⤢
            </button>
            <div className="mla-chart">
              <Line data={data} options={chartOptions} />
            </div>
          </div>
          {/* Pie Chart */}
          <div className="mla-chart-box">
            <h3>Pie Chart</h3>
            <button
              className="mla-expand-btn"
              onClick={() => setExpandedChart('pie')}
              title="Expand Chart"
            >
              ⤢
            </button>
            <div className="mla-chart">
              <Pie data={data} options={chartOptions} />
            </div>
          </div>
        </div>
      )}
      {expandedChart && (
        <div className="mla-fullscreen-overlay">
          <div className="mla-fullscreen-chart">
            <button
              className="mla-close-btn"
              onClick={() => setExpandedChart(null)}
              title="Restore to Original Size"
            >
              ✕
            </button>
            {renderChart(expandedChart)}
          </div>
        </div>
      )}
    </div>
  );
};

export default MultiLevelAnalysis;
